<template>
  <div>
    <a-row>
      <a-col span="12">
        <a-descriptions
          title="基本信息"
          :column="1"
          class="simiot-descriptions"
        >
          <!-- <a-descriptions-item label="客户ID">
            {{ agent.id }}
          </a-descriptions-item> -->

          <a-descriptions-item label="客户名称">
            {{ agent.name }}
          </a-descriptions-item>

          <a-descriptions-item label="所在城市">
            {{ agent.region }}
          </a-descriptions-item>

          <a-descriptions-item label="登录账号">
            {{ agent.username }}
          </a-descriptions-item>

          <a-descriptions-item label="联系对象">
            {{ agent.full_name }}
          </a-descriptions-item>

          <a-descriptions-item label="手机号码">
            {{ agent.phone_number }}
          </a-descriptions-item>

          <a-descriptions-item label="联系邮箱">
            {{ agent.email }}
          </a-descriptions-item>

          <a-descriptions-item label="责任销售">
            {{ agent.sale_full_name }}
          </a-descriptions-item>

          <a-descriptions-item label="签约主体">
            {{ agent.contracting_party }}
          </a-descriptions-item>

          <a-descriptions-item label="服务协议">
            <a :href="platformServiceAgreement.resource_url" target="_blank">
              {{ platformServiceAgreement.name }}
            </a>
          </a-descriptions-item>

          <a-descriptions-item label="IP地址">
            {{ agent.ip }}
          </a-descriptions-item>

          <a-descriptions-item label="创建时间">
            {{ agent.created_at }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col span="12">
        <a-descriptions
          title="操作历史"
          :column="1"
          class="simiot-descriptions"
        />

        <operation-history-list
          :reference-id="this.agentId"
          reference-type="Agent"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { findAgent } from '@/api/agent'
import OperationHistoryList from '@/views/operation_histories'

export default {
  name: 'ShowAgent',
  components: { OperationHistoryList },
  data() {
    return {
      agent: {},
      platformServiceAgreement: {}
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findAgent(this.agentId).then((res) => {
        if (res.code === 0) {
          this.agent = res.data
          this.platformServiceAgreement = res.data.platform_service_agreement
        }
      })
    }
  }
}
</script>
